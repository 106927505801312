import React from 'react'
import { getFileExt } from '../../../../utils/getFileExt';
import { URL_SELFSERVICE_OP_FILES } from '../../../../constants/url';
import { postFile } from '../../../../utils/fetch';

import { useDispatch } from 'react-redux';
import { startGetOrdenesBiertasByProveedorID } from '../../../../context/actions/facturacionActions';

export const useProps = (orden) => {
    const dispatch = useDispatch()
    const [body, setBody] = React.useState({})

    const propsXml = {
        beforeUpload: (file) => {
            if (getFileExt(file.name).toLowerCase() !== 'xml') {
                setBody({ ...body, complementoXml: null, complementoXmlErr: `El archivo ${file.name} no es XML` });
                return false;
            } else {
                setBody({ ...body, complementoXml: file, complementoXmlErr: null });
            }
            return false;
        },
        maxCount: 1,
        showUploadList: false,
    };

    const propsPdf = {
        beforeUpload: (file) => {
            if (getFileExt(file.name).toLowerCase() !== 'pdf') {
                setBody({ ...body, complementoPdf: null, complementoPdfErr: `El archivo ${file.name} no es PDF` });
                return false;
            } else if (body.verificacion?.name === file.name) {
                setBody({ ...body, complementoPdf: null, complementoPdfErr: `El archivo ${file.name} no puede ser igual al de verificación` });
                return false;
            } else {
                setBody({ ...body, complementoPdf: file, complementoPdfErr: null });
            }
            return false;
        },
        maxCount: 1,
        showUploadList: false,
    };



    const disabled = !body.complementoXml || !body.complementoPdf 

    const submit = async () => {
  

        setBody({ ...body, respuestaOk: null, respuestaErr: null, loading: true });
        const formData = new FormData();
        formData.append('ComplementoPagoPDF', body.complementoXml);
        formData.append('ComplementoPagoXml', body.complementoPdf);
        formData.append("OrdenPagoID", orden.ordenPagoID)

        const resp = await postFile(`${URL_SELFSERVICE_OP_FILES}/PostDocumentosComplemento`, formData)

        if (resp.code === 0) {
            setBody({ respuestaOk: resp.data, loading: false });
            
            setTimeout(() => {
                dispatch(startGetOrdenesBiertasByProveedorID())
            }, 5000);
            
         
        } else {
            setBody({ ...body, respuestaErr: resp.error, loading: false });
        }

    }
    
    return { body, propsXml, propsPdf, disabled, submit }
}
