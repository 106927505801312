import { Badge, Button, Col, Row, Space, Tooltip } from "antd";
import React, { useState } from "react";

import { Crono } from "./_Crono";

import { ModalMasInfo } from "./modalMasInfo/_ModalMasInfo";
import imgMaps from "../../../assets/IconMaps.jpeg";
import { ModalCarretero } from "./modalCarretero/ModalCarretero";
import { ModalLocal } from "./modalLocal/ModalLocal";
import { useDispatch } from "react-redux";

const RowAsistenciaPorConfirmar = ({ asistencia, provAsignado }) => {
  const dispatch = useDispatch();

  const [openModalCarretero, setOpenModalCarretero] = useState(false);
  const [openModalLocal, setOpenModalLocal] = useState(false);
  const [asistenciaFullSelected, setAsistenciaFullSelected] = useState({});

  const [openMasInfo, setOpenMasInfo] = useState(false);

  const masInfoClick = (asistencia) => {
    setAsistenciaFullSelected(asistencia);
    setOpenMasInfo(true);
  };

  const openMap = (asistencia, tipo) => {
    let url = "";
    if (tipo === "origen") {
      url = `https://www.google.com/maps/search/?api=1&query=${asistencia.latLngOrigen}`;
    } else {
      url = `https://www.google.com/maps/search/?api=1&query=${asistencia.latLngDestino}`;
    }
    window.open(url, "_blank");
  };

  const onClick = () => {
    // dispatch(setAsistenciaSelected(asistencia.asistenciaID));
    if (asistencia.localCarretero === "local") setOpenModalLocal(true);

    if (asistencia.localCarretero === "carretero") setOpenModalCarretero(true);
  };


  return (
    <>
      <Badge.Ribbon
        text={
          asistencia.localCarretero === "local"
            ? "Local"
            : asistencia.costoPropuesto
            ? "Propuesta enviada"
            : "carretero"
        }
        color={
          !provAsignado && !asistencia.propuesta
            ? asistencia.localCarretero === "local"
              ? "green"
              : "blue"
            : provAsignado
            ? "red"
            : "#faad14"
        }
      >
        <Row
          gutter={10}
          className="rowAsistencias"
          style={{ marginBottom: 15, cursor: "default" }}
        >
          <Col span={3}>
            {!provAsignado && (
              <Crono fechaSugiereProveedor={asistencia.fechaSugiereProveedor} />
            )}
          </Col>

          <Col span={3} style={{ paddingTop: 4 }}>
            {asistencia.tipoServicio}
          </Col>
          <Col span={6} style={{ paddingTop: 4 }}>
            <Tooltip title="Ver en Maps">
              <img
                onClick={() => openMap(asistencia, "origen")}
                src={imgMaps}
                alt="mapa"
                className="mapaIcon"
              />
            </Tooltip>{" "}
            {asistencia.origen}
          </Col>

          <Col span={6} style={{ paddingTop: 4 }}>
            <Tooltip title="Ver en Maps">
              <img
                src={imgMaps}
                onClick={() => openMap(asistencia, "destino")}
                alt="mapa"
                className="mapaIcon"
              />
            </Tooltip>{" "}
            {asistencia.destino}
          </Col>
          <Col span={4}>
            <Space>
              <Button
                onClick={() => masInfoClick(asistencia)}
                size="small"
                type="default"
                danger
              >
                + Info
              </Button>

              {!asistencia.costoPropuesto && (
                <Button
                  onClick={() => onClick(asistencia)}
                  size="small"
                  type="primary"
                >
                  Tomar
                </Button>
              )}
            </Space>
          </Col>
        </Row>
      </Badge.Ribbon>

      <ModalMasInfo
        open={openMasInfo}
        setOpen={setOpenMasInfo}
        asistencia={asistenciaFullSelected}
      />

      {/* //Modal locales */}
      {!provAsignado && (
        <ModalLocal
          open={openModalLocal}
          setOpen={setOpenModalLocal}
          asistencia={asistencia}
        />
      )}

      {/* //Modal carretero */}

      <ModalCarretero
        open={openModalCarretero}
        setOpen={setOpenModalCarretero}
        asistencia={asistencia}
      />
    </>
  );
};

export default RowAsistenciaPorConfirmar;
