export function getCronoTime(fechaPasada, less) {
  let f = new Date(fechaPasada);
  const fechaActual = new Date();
  const diferencia = fechaActual - f;

  const segundos = Math.floor(diferencia / 1000);
  const minutos = Math.floor(segundos / 60);
  const horas = Math.floor(minutos / 60);
  const dias = Math.floor(horas / 24);

  const segundosRestantes = segundos % 60;
  const minutosRestantes = minutos % 60;
  const horasRestantes = horas % 24;

const ss = segundosRestantes > 9 ? segundosRestantes : `0${segundosRestantes}`;
const mm = minutosRestantes > 9 ? minutosRestantes : `0${minutos}`

  const diass = dias > 0 ? `${dias}d ` : "";
  let horass = "";
  
  if (dias === 0 && horasRestantes === 0) horass = "";
  else horass = horasRestantes > 0 ? `${horasRestantes}:` : `0${horasRestantes}:`;

  return `${diass} ${horass}${mm}:${ss}`;
}

export const getCronoTimeOLD = (time, less) => {
  const f = new Date(time);
  console.log({ time, f });

  const dd = Number(f.getDay()) > 9 ? f.getDay() : `0${f.getDay()}`;

  const hh = Number(f.getHours()) > 9 ? f.getHours() : `0${f.getHours()}`;
  const mm = Number(f.getMinutes()) > 9 ? f.getMinutes() : `0${f.getMinutes()}`;
  const ss = Number(f.getSeconds()) > 9 ? f.getSeconds() : `0${f.getSeconds()}`;
  const mil =
    Number(f.getMilliseconds()) > 9
      ? f.getMilliseconds() > 99
        ? f.getMilliseconds()
        : `0${f.getMilliseconds()}`
      : `00${f.getMilliseconds()}`;

  //  return `${mm - Number(less)}:${ss}:${mil}`

  const dias = dd > 0 ? `${dd} días ` : "";

  return `${dias} ${hh} ${mm - Number(less)}' ${ss}''`;
};

export const getCronoTimeSin = (ini) => {
  // Fecha dada
  const fechaDada = new Date(ini);

  const fin = new Date();

  // Diferencia en milisegundos
  const diferencia = fin - fechaDada;

  // Convertir la diferencia a días, horas, minutos y segundos
  const dias = Math.floor(diferencia / (1000 * 60 * 60 * 24));
  const horas = Math.floor(
    (diferencia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutos = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
  const segundos = Math.floor((diferencia % (1000 * 60)) / 1000);

  const ss = segundos > 9 ? segundos : `0${segundos}`;
  const mm = minutos > 9 ? minutos : `0${minutos}`;

  return `${dias} días, ${horas} horas, ${mm} minutos y ${ss} segundos`;
};
