import { Col, Input, Row } from "antd";
import ModalPage from "../../../../layout/ModalPage";
import LabelNumber from "../../../../components/input/LabelNumber";
import { SelectSucursales } from "../../../../components/select/SelectSucursales";
import { useForm } from "./useForm";

export const ModalCarretero = ({ open, setOpen, asistencia }) => {
  const {
    soloUnaSucursal,
    textoSucursal,
    handleOk,
    handleChange,
    changeSelect,
    handleText
  } = useForm(asistencia);

  const submit = () => {
    handleOk();
    setOpen(false);
  };

  //
  return (
    <ModalPage
      handleOk={submit}
      handleCancel={() => setOpen(false)}
      isVisible={open}
      titulo="Tomar Servicio - CARRETERO"
      okLabel="Aceptar Servicio"
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {soloUnaSucursal ? (
            <div
              style={{ fontWeight: 600, textAlign: "center", marginTop: 10 }}
            >
              Sucursal:
              {textoSucursal}
            </div>
          ) : (
            <SelectSucursales
              label="Sucursal encargada"
              onChange={changeSelect}
            />
          )}
        </Col>
        <Col span={12}>
          <LabelNumber
            onChange={handleChange}
            name="costoPropuesto"
            label="Costo Propuesto"
          />
        </Col>
        <Col span={12}>
          <LabelNumber
            onChange={handleChange}
            name="tiempoLlegadaPropuesto"
            label="Tiempo de Arribo"
          />
        </Col>
        <Col span={24}>
          <div className="labelImput">Descripción de Costos</div>

          <Input.TextArea
            onChange={handleText}
            name="DescripcionCostoPropuesto"
    
          />
        </Col>
      </Row>
      <br />
    </ModalPage>
  );
};
