import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startPostCarretero } from "../../../../context/actions/asistenciasActions";

export const useForm = (asistencia) => {
  const dispatch = useDispatch();
  const [soloUnaSucursal, setSoloUnaSucursal] = React.useState(false);
  const [textoSucursal, setTextoSucursal] = React.useState("");
  const [body, setBody] = React.useState({
    ProveedorPropuestoID: asistencia.proveedorPropuestoID,
  });

  const { sucursales } = useSelector(
    (state) => state.proveedorInfoReducer.proveedorInfo
  );

  //
  useEffect(() => {
    if (sucursales && sucursales.length === 1) {
      setSoloUnaSucursal(true);
      setTextoSucursal(sucursales[0].nombre);
      setBody({
        ...body,
        proveedorSucursalID: sucursales[0].proveedorSucursalID,
      });
    }
  }, [sucursales]);

  //
  const handleChange = ({ target }) =>
    setBody({ ...body, [target.name]: Number(target.value) });

  const handleText = ({ target }) =>
    setBody({ ...body, [target.name]: target.value });
  //
  const changeSelect = (val) => setBody({ ...body, proveedorSucursalID: val });

  //
  const handleOk = () => dispatch(startPostCarretero(body));


  //
  return {
    soloUnaSucursal,
    textoSucursal,
    handleOk,
    handleChange,
    changeSelect,
    handleText
  };
};
